:root {
  --font-family: system-ui, -apple-system, "Segoe UI", "Roboto", "Ubuntu", "Cantarell", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --line-height: 1.5;
  --font-weight: 400;
  --font-size: 16px;
  --border-radius: .25rem;
  --border-width: 1px;
  --outline-width: 3px;
  --spacing: 1rem;
  --typography-spacing-vertical: 1.5rem;
  --block-spacing-vertical: calc(var(--spacing) * 2);
  --block-spacing-horizontal: var(--spacing);
  --grid-spacing-vertical: 0;
  --grid-spacing-horizontal: var(--spacing);
  --form-element-spacing-vertical: .75rem;
  --form-element-spacing-horizontal: 1rem;
  --nav-element-spacing-vertical: 1rem;
  --nav-element-spacing-horizontal: .5rem;
  --nav-link-spacing-vertical: .5rem;
  --nav-link-spacing-horizontal: .5rem;
  --form-label-font-weight: var(--font-weight);
  --transition: .2s ease-in-out;
  --modal-overlay-backdrop-filter: blur(.25rem);
}

@media (min-width: 576px) {
  :root {
    --font-size: 17px;
  }
}

@media (min-width: 768px) {
  :root {
    --font-size: 18px;
  }
}

@media (min-width: 992px) {
  :root {
    --font-size: 19px;
  }
}

@media (min-width: 1200px) {
  :root {
    --font-size: 20px;
  }
}

@media (min-width: 576px) {
  body > footer, body > header, body > main, section {
    --block-spacing-vertical: calc(var(--spacing) * 2.5);
  }
}

@media (min-width: 768px) {
  body > footer, body > header, body > main, section {
    --block-spacing-vertical: calc(var(--spacing) * 3);
  }
}

@media (min-width: 992px) {
  body > footer, body > header, body > main, section {
    --block-spacing-vertical: calc(var(--spacing) * 3.5);
  }
}

@media (min-width: 1200px) {
  body > footer, body > header, body > main, section {
    --block-spacing-vertical: calc(var(--spacing) * 4);
  }
}

@media (min-width: 576px) {
  article {
    --block-spacing-horizontal: calc(var(--spacing) * 1.25);
  }
}

@media (min-width: 768px) {
  article {
    --block-spacing-horizontal: calc(var(--spacing) * 1.5);
  }
}

@media (min-width: 992px) {
  article {
    --block-spacing-horizontal: calc(var(--spacing) * 1.75);
  }
}

@media (min-width: 1200px) {
  article {
    --block-spacing-horizontal: calc(var(--spacing) * 2);
  }
}

dialog > article {
  --block-spacing-vertical: calc(var(--spacing) * 2);
  --block-spacing-horizontal: var(--spacing);
}

@media (min-width: 576px) {
  dialog > article {
    --block-spacing-vertical: calc(var(--spacing) * 2.5);
    --block-spacing-horizontal: calc(var(--spacing) * 1.25);
  }
}

@media (min-width: 768px) {
  dialog > article {
    --block-spacing-vertical: calc(var(--spacing) * 3);
    --block-spacing-horizontal: calc(var(--spacing) * 1.5);
  }
}

a {
  --text-decoration: none;
}

a.contrast, a.secondary {
  --text-decoration: underline;
}

small {
  --font-size: .875em;
}

h1, h2, h3, h4, h5, h6 {
  --font-weight: 700;
}

h1 {
  --font-size: 2rem;
  --typography-spacing-vertical: 3rem;
}

h2 {
  --font-size: 1.75rem;
  --typography-spacing-vertical: 2.625rem;
}

h3 {
  --font-size: 1.5rem;
  --typography-spacing-vertical: 2.25rem;
}

h4 {
  --font-size: 1.25rem;
  --typography-spacing-vertical: 1.874rem;
}

h5 {
  --font-size: 1.125rem;
  --typography-spacing-vertical: 1.6875rem;
}

[type="checkbox"], [type="radio"] {
  --border-width: 2px;
}

[type="checkbox"][role="switch"], tfoot td, tfoot th, thead td, thead th {
  --border-width: 3px;
}

:not(thead, tfoot) > * > td {
  --font-size: .875em;
}

code, kbd, pre, samp {
  --font-family: "Menlo", "Consolas", "Roboto Mono", "Ubuntu Monospace", "Noto Mono", "Oxygen Mono", "Liberation Mono", monospace, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

kbd {
  --font-weight: bolder;
}

:root:not([data-theme="dark"]), [data-theme="light"] {
  --background-color: #fff;
  --color: #415462;
  --h1-color: #1b2832;
  --h2-color: #24333e;
  --h3-color: #2c3d49;
  --h4-color: #374956;
  --h5-color: #415462;
  --h6-color: #4d606d;
  --muted-color: #73828c;
  --muted-border-color: #edf0f3;
  --primary: #1095c1;
  --primary-hover: #08769b;
  --primary-focus: #1095c120;
  --primary-inverse: #fff;
  --secondary: #596b78;
  --secondary-hover: #415462;
  --secondary-focus: #596b7820;
  --secondary-inverse: #fff;
  --contrast: #1b2832;
  --contrast-hover: #000;
  --contrast-focus: #596b7820;
  --contrast-inverse: #fff;
  --mark-background-color: #fff2ca;
  --mark-color: #543a26;
  --ins-color: #388e3c;
  --del-color: #c62828;
  --blockquote-border-color: var(--muted-border-color);
  --blockquote-footer-color: var(--muted-color);
  --button-box-shadow: 0 0 0 #0000;
  --button-hover-box-shadow: 0 0 0 #0000;
  --form-element-background-color: transparent;
  --form-element-border-color: #a2afb9;
  --form-element-color: var(--color);
  --form-element-placeholder-color: var(--muted-color);
  --form-element-active-background-color: transparent;
  --form-element-active-border-color: var(--primary);
  --form-element-focus-color: var(--primary-focus);
  --form-element-disabled-background-color: #d5dce2;
  --form-element-disabled-border-color: #a2afb9;
  --form-element-disabled-opacity: .5;
  --form-element-invalid-border-color: #c62828;
  --form-element-invalid-active-border-color: #d32f2f;
  --form-element-invalid-focus-color: #d32f2f20;
  --form-element-valid-border-color: #388e3c;
  --form-element-valid-active-border-color: #43a047;
  --form-element-valid-focus-color: #43a04720;
  --switch-background-color: #bbc6ce;
  --switch-color: var(--primary-inverse);
  --switch-checked-background-color: var(--primary);
  --range-border-color: #d5dce2;
  --range-active-border-color: #bbc6ce;
  --range-thumb-border-color: var(--background-color);
  --range-thumb-color: var(--secondary);
  --range-thumb-hover-color: var(--secondary-hover);
  --range-thumb-active-color: var(--primary);
  --table-border-color: var(--muted-border-color);
  --table-row-stripped-background-color: #f6f8f9;
  --code-background-color: #edf0f3;
  --code-color: var(--muted-color);
  --code-kbd-background-color: var(--contrast);
  --code-kbd-color: var(--contrast-inverse);
  --code-tag-color: #b34d80;
  --code-property-color: #3d888f;
  --code-value-color: #986;
  --code-comment-color: #a2afb9;
  --accordion-border-color: var(--muted-border-color);
  --accordion-close-summary-color: var(--color);
  --accordion-open-summary-color: var(--muted-color);
  --card-background-color: var(--background-color);
  --card-border-color: var(--muted-border-color);
  --card-box-shadow: .0145rem .029rem .174rem #1b283204, .0335rem .067rem .402rem #1b283206, .0625rem .125rem .75rem #1b283208, .1125rem .225rem 1.35rem #1b283209, .2085rem .417rem 2.502rem #1b28320b, .5rem 1rem 6rem #1b28320f, 0 0 0 .0625rem #1b283204;
  --card-sectionning-background-color: #fbfbfc;
  --dropdown-background-color: #fbfbfc;
  --dropdown-border-color: #e1e6eb;
  --dropdown-box-shadow: var(--card-box-shadow);
  --dropdown-color: var(--color);
  --dropdown-hover-background-color: #edf0f3;
  --modal-overlay-background-color: #d5dce2b3;
  --progress-background-color: #d5dce2;
  --progress-color: var(--primary);
  --loading-spinner-opacity: .5;
  --tooltip-background-color: var(--contrast);
  --tooltip-color: var(--contrast-inverse);
  --icon-checkbox: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-chevron: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(65, 84, 98)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-chevron-button: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-chevron-button-inverse: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-close: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(115, 130, 140)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
  --icon-date: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(65, 84, 98)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='3' y='4' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Cline x1='16' y1='2' x2='16' y2='6'%3E%3C/line%3E%3Cline x1='8' y1='2' x2='8' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='10' x2='21' y2='10'%3E%3C/line%3E%3C/svg%3E");
  --icon-invalid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(198, 40, 40)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E");
  --icon-minus: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3C/svg%3E");
  --icon-search: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(65, 84, 98)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
  --icon-time: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(65, 84, 98)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cpolyline points='12 6 12 12 16 14'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-valid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(56, 142, 60)' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
  color-scheme: light;
}

@media only screen and (prefers-color-scheme: dark) {
  :root:not([data-theme]) {
    --background-color: #11191f;
    --color: #bbc6ce;
    --h1-color: #edf0f3;
    --h2-color: #e1e6eb;
    --h3-color: #d5dce2;
    --h4-color: #c8d1d8;
    --h5-color: #bbc6ce;
    --h6-color: #afbbc4;
    --muted-color: #73828c;
    --muted-border-color: #1f2d38;
    --primary: #1095c1;
    --primary-hover: #1ab3e6;
    --primary-focus: #1095c140;
    --primary-inverse: #fff;
    --secondary: #596b78;
    --secondary-hover: #73828c;
    --secondary-focus: #73828c40;
    --secondary-inverse: #fff;
    --contrast: #edf0f3;
    --contrast-hover: #fff;
    --contrast-focus: #73828c40;
    --contrast-inverse: #000;
    --mark-background-color: #d1c284;
    --mark-color: #11191f;
    --ins-color: #388e3c;
    --del-color: #c62828;
    --blockquote-border-color: var(--muted-border-color);
    --blockquote-footer-color: var(--muted-color);
    --button-box-shadow: 0 0 0 #0000;
    --button-hover-box-shadow: 0 0 0 #0000;
    --form-element-background-color: #11191f;
    --form-element-border-color: #374956;
    --form-element-color: var(--color);
    --form-element-placeholder-color: var(--muted-color);
    --form-element-active-background-color: var(--form-element-background-color);
    --form-element-active-border-color: var(--primary);
    --form-element-focus-color: var(--primary-focus);
    --form-element-disabled-background-color: #2c3d49;
    --form-element-disabled-border-color: #415462;
    --form-element-disabled-opacity: .5;
    --form-element-invalid-border-color: #b71c1c;
    --form-element-invalid-active-border-color: #c62828;
    --form-element-invalid-focus-color: #c6282840;
    --form-element-valid-border-color: #2e7d32;
    --form-element-valid-active-border-color: #388e3c;
    --form-element-valid-focus-color: #388e3c40;
    --switch-background-color: #374956;
    --switch-color: var(--primary-inverse);
    --switch-checked-background-color: var(--primary);
    --range-border-color: #24333e;
    --range-active-border-color: #2c3d49;
    --range-thumb-border-color: var(--background-color);
    --range-thumb-color: var(--secondary);
    --range-thumb-hover-color: var(--secondary-hover);
    --range-thumb-active-color: var(--primary);
    --table-border-color: var(--muted-border-color);
    --table-row-stripped-background-color: #73828c0d;
    --code-background-color: #18232c;
    --code-color: var(--muted-color);
    --code-kbd-background-color: var(--contrast);
    --code-kbd-color: var(--contrast-inverse);
    --code-tag-color: #a65980;
    --code-property-color: #599fa6;
    --code-value-color: #8c8473;
    --code-comment-color: #4d606d;
    --accordion-border-color: var(--muted-border-color);
    --accordion-active-summary-color: var(--primary);
    --accordion-close-summary-color: var(--color);
    --accordion-open-summary-color: var(--muted-color);
    --card-background-color: #141e26;
    --card-border-color: var(--card-background-color);
    --card-box-shadow: .0145rem .029rem .174rem #00000004, .0335rem .067rem .402rem #00000006, .0625rem .125rem .75rem #00000008, .1125rem .225rem 1.35rem #00000009, .2085rem .417rem 2.502rem #0000000b, .5rem 1rem 6rem #0000000f, 0 0 0 .0625rem #00000004;
    --card-sectionning-background-color: #18232c;
    --dropdown-background-color: #1b2832;
    --dropdown-border-color: #24333e;
    --dropdown-box-shadow: var(--card-box-shadow);
    --dropdown-color: var(--color);
    --dropdown-hover-background-color: #24333ebf;
    --modal-overlay-background-color: #24333ecc;
    --progress-background-color: #24333e;
    --progress-color: var(--primary);
    --loading-spinner-opacity: .5;
    --tooltip-background-color: var(--contrast);
    --tooltip-color: var(--contrast-inverse);
    --icon-checkbox: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
    --icon-chevron: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(162, 175, 185)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    --icon-chevron-button: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    --icon-chevron-button-inverse: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(0, 0, 0)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    --icon-close: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(115, 130, 140)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
    --icon-date: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(162, 175, 185)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='3' y='4' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Cline x1='16' y1='2' x2='16' y2='6'%3E%3C/line%3E%3Cline x1='8' y1='2' x2='8' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='10' x2='21' y2='10'%3E%3C/line%3E%3C/svg%3E");
    --icon-invalid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(183, 28, 28)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E");
    --icon-minus: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3C/svg%3E");
    --icon-search: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(162, 175, 185)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
    --icon-time: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(162, 175, 185)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cpolyline points='12 6 12 12 16 14'%3E%3C/polyline%3E%3C/svg%3E");
    --icon-valid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(46, 125, 50)' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
    color-scheme: dark;
  }
}

[data-theme="dark"] {
  --background-color: #11191f;
  --color: #bbc6ce;
  --h1-color: #edf0f3;
  --h2-color: #e1e6eb;
  --h3-color: #d5dce2;
  --h4-color: #c8d1d8;
  --h5-color: #bbc6ce;
  --h6-color: #afbbc4;
  --muted-color: #73828c;
  --muted-border-color: #1f2d38;
  --primary: #1095c1;
  --primary-hover: #1ab3e6;
  --primary-focus: #1095c140;
  --primary-inverse: #fff;
  --secondary: #596b78;
  --secondary-hover: #73828c;
  --secondary-focus: #73828c40;
  --secondary-inverse: #fff;
  --contrast: #edf0f3;
  --contrast-hover: #fff;
  --contrast-focus: #73828c40;
  --contrast-inverse: #000;
  --mark-background-color: #d1c284;
  --mark-color: #11191f;
  --ins-color: #388e3c;
  --del-color: #c62828;
  --blockquote-border-color: var(--muted-border-color);
  --blockquote-footer-color: var(--muted-color);
  --button-box-shadow: 0 0 0 #0000;
  --button-hover-box-shadow: 0 0 0 #0000;
  --form-element-background-color: #11191f;
  --form-element-border-color: #374956;
  --form-element-color: var(--color);
  --form-element-placeholder-color: var(--muted-color);
  --form-element-active-background-color: var(--form-element-background-color);
  --form-element-active-border-color: var(--primary);
  --form-element-focus-color: var(--primary-focus);
  --form-element-disabled-background-color: #2c3d49;
  --form-element-disabled-border-color: #415462;
  --form-element-disabled-opacity: .5;
  --form-element-invalid-border-color: #b71c1c;
  --form-element-invalid-active-border-color: #c62828;
  --form-element-invalid-focus-color: #c6282840;
  --form-element-valid-border-color: #2e7d32;
  --form-element-valid-active-border-color: #388e3c;
  --form-element-valid-focus-color: #388e3c40;
  --switch-background-color: #374956;
  --switch-color: var(--primary-inverse);
  --switch-checked-background-color: var(--primary);
  --range-border-color: #24333e;
  --range-active-border-color: #2c3d49;
  --range-thumb-border-color: var(--background-color);
  --range-thumb-color: var(--secondary);
  --range-thumb-hover-color: var(--secondary-hover);
  --range-thumb-active-color: var(--primary);
  --table-border-color: var(--muted-border-color);
  --table-row-stripped-background-color: #73828c0d;
  --code-background-color: #18232c;
  --code-color: var(--muted-color);
  --code-kbd-background-color: var(--contrast);
  --code-kbd-color: var(--contrast-inverse);
  --code-tag-color: #a65980;
  --code-property-color: #599fa6;
  --code-value-color: #8c8473;
  --code-comment-color: #4d606d;
  --accordion-border-color: var(--muted-border-color);
  --accordion-active-summary-color: var(--primary);
  --accordion-close-summary-color: var(--color);
  --accordion-open-summary-color: var(--muted-color);
  --card-background-color: #141e26;
  --card-border-color: var(--card-background-color);
  --card-box-shadow: .0145rem .029rem .174rem #00000004, .0335rem .067rem .402rem #00000006, .0625rem .125rem .75rem #00000008, .1125rem .225rem 1.35rem #00000009, .2085rem .417rem 2.502rem #0000000b, .5rem 1rem 6rem #0000000f, 0 0 0 .0625rem #00000004;
  --card-sectionning-background-color: #18232c;
  --dropdown-background-color: #1b2832;
  --dropdown-border-color: #24333e;
  --dropdown-box-shadow: var(--card-box-shadow);
  --dropdown-color: var(--color);
  --dropdown-hover-background-color: #24333ebf;
  --modal-overlay-background-color: #24333ecc;
  --progress-background-color: #24333e;
  --progress-color: var(--primary);
  --loading-spinner-opacity: .5;
  --tooltip-background-color: var(--contrast);
  --tooltip-color: var(--contrast-inverse);
  --icon-checkbox: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-chevron: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(162, 175, 185)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-chevron-button: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-chevron-button-inverse: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(0, 0, 0)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-close: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(115, 130, 140)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
  --icon-date: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(162, 175, 185)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='3' y='4' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Cline x1='16' y1='2' x2='16' y2='6'%3E%3C/line%3E%3Cline x1='8' y1='2' x2='8' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='10' x2='21' y2='10'%3E%3C/line%3E%3C/svg%3E");
  --icon-invalid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(183, 28, 28)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E");
  --icon-minus: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3C/svg%3E");
  --icon-search: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(162, 175, 185)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
  --icon-time: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(162, 175, 185)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cpolyline points='12 6 12 12 16 14'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-valid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(46, 125, 50)' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
  color-scheme: dark;
}

[type="checkbox"], [type="radio"], [type="range"], progress {
  accent-color: var(--primary);
}

*, :after, :before {
  box-sizing: border-box;
  background-repeat: no-repeat;
}

:after, :before {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

:where(:root) {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background-color: var(--background-color);
  color: var(--color);
  font-weight: var(--font-weight);
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-family: var(--font-family);
  text-rendering: optimizelegibility;
  overflow-wrap: break-word;
  cursor: default;
  tab-size: 4;
}

main {
  display: block;
}

body {
  width: 100%;
  margin: 0;
}

body > footer, body > header, body > main {
  width: 100%;
  padding: var(--block-spacing-vertical) 0;
  margin-left: auto;
  margin-right: auto;
}

.container, .container-fluid {
  width: 100%;
  padding-right: var(--spacing);
  padding-left: var(--spacing);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 510px;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 700px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 920px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1130px;
  }
}

section {
  margin-bottom: var(--block-spacing-vertical);
}

.grid {
  grid-column-gap: var(--grid-spacing-horizontal);
  grid-row-gap: var(--grid-spacing-vertical);
  grid-template-columns: 1fr;
  margin: 0;
  display: grid;
}

@media (min-width: 992px) {
  .grid {
    grid-template-columns: repeat(auto-fit, minmax(0%, 1fr));
  }
}

.grid > * {
  min-width: 0;
}

figure {
  margin: 0;
  padding: 0;
  display: block;
  overflow-x: auto;
}

figure figcaption {
  padding: calc(var(--spacing) * .5) 0;
  color: var(--muted-color);
}

b, strong {
  font-weight: bolder;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

address, blockquote, dl, figure, form, ol, p, pre, table, ul {
  margin-top: 0;
  margin-bottom: var(--typography-spacing-vertical);
  color: var(--color);
  font-style: normal;
  font-weight: var(--font-weight);
  font-size: var(--font-size);
}

[role="link"], a {
  --color: var(--primary);
  --background-color: transparent;
  background-color: var(--background-color);
  color: var(--color);
  -webkit-text-decoration: var(--text-decoration);
  -webkit-text-decoration: var(--text-decoration);
  text-decoration: var(--text-decoration);
  transition: background-color var(--transition), color var(--transition), box-shadow var(--transition), -webkit-text-decoration var(--transition);
  transition: background-color var(--transition), color var(--transition), text-decoration var(--transition), box-shadow var(--transition);
  transition: background-color var(--transition), color var(--transition), text-decoration var(--transition), box-shadow var(--transition), -webkit-text-decoration var(--transition);
  outline: 0;
}

[role="link"]:is([aria-current], :hover, :active, :focus), a:is([aria-current], :hover, :active, :focus) {
  --color: var(--primary-hover);
  --text-decoration: underline;
}

[role="link"]:focus, a:focus {
  --background-color: var(--primary-focus);
}

[role="link"].secondary, a.secondary {
  --color: var(--secondary);
}

[role="link"].secondary:is([aria-current], :hover, :active, :focus), a.secondary:is([aria-current], :hover, :active, :focus) {
  --color: var(--secondary-hover);
}

[role="link"].secondary:focus, a.secondary:focus {
  --background-color: var(--secondary-focus);
}

[role="link"].contrast, a.contrast {
  --color: var(--contrast);
}

[role="link"].contrast:is([aria-current], :hover, :active, :focus), a.contrast:is([aria-current], :hover, :active, :focus) {
  --color: var(--contrast-hover);
}

[role="link"].contrast:focus, a.contrast:focus {
  --background-color: var(--contrast-focus);
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: var(--typography-spacing-vertical);
  color: var(--color);
  font-weight: var(--font-weight);
  font-size: var(--font-size);
  font-family: var(--font-family);
}

h1 {
  --color: var(--h1-color);
}

h2 {
  --color: var(--h2-color);
}

h3 {
  --color: var(--h3-color);
}

h4 {
  --color: var(--h4-color);
}

h5 {
  --color: var(--h5-color);
}

h6 {
  --color: var(--h6-color);
}

:where(address, blockquote, dl, figure, form, ol, p, pre, table, ul) ~ :is(h1, h2, h3, h4, h5, h6) {
  margin-top: var(--typography-spacing-vertical);
}

.headings, hgroup {
  margin-bottom: var(--typography-spacing-vertical);
}

.headings > *, hgroup > * {
  margin-bottom: 0;
}

.headings > :last-child, hgroup > :last-child {
  --color: var(--muted-color);
  --font-weight: unset;
  font-family: unset;
  font-size: 1rem;
}

p {
  margin-bottom: var(--typography-spacing-vertical);
}

small {
  font-size: var(--font-size);
}

:where(dl, ol, ul) {
  padding-right: 0;
  padding-left: var(--spacing);
  -webkit-padding-start: var(--spacing);
  -webkit-padding-end: 0;
  padding-inline-start: var(--spacing);
  padding-inline-end: 0;
}

:where(dl, ol, ul) li {
  margin-bottom: calc(var(--typography-spacing-vertical) * .25);
}

:where(dl, ol, ul) :is(dl, ol, ul) {
  margin: 0;
  margin-top: calc(var(--typography-spacing-vertical) * .25);
}

ul li {
  list-style: square;
}

mark {
  background-color: var(--mark-background-color);
  color: var(--mark-color);
  vertical-align: baseline;
  padding: .125rem .25rem;
}

blockquote {
  margin: var(--typography-spacing-vertical) 0;
  padding: var(--spacing);
  border-right: none;
  border-left: .25rem solid var(--blockquote-border-color);
  -webkit-border-start: .25rem solid var(--blockquote-border-color);
  border-inline-start: .25rem solid var(--blockquote-border-color);
  -webkit-border-end: none;
  border-inline-end: none;
  display: block;
}

blockquote footer {
  margin-top: calc(var(--typography-spacing-vertical) * .5);
  color: var(--blockquote-footer-color);
}

abbr[title] {
  cursor: help;
  border-bottom: 1px dotted;
  text-decoration: none;
}

ins {
  color: var(--ins-color);
  text-decoration: none;
}

del {
  color: var(--del-color);
}

::selection {
  background-color: var(--primary-focus);
}

:where(audio, canvas, iframe, img, svg, video) {
  vertical-align: middle;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

:where(iframe) {
  border-style: none;
}

img {
  max-width: 100%;
  height: auto;
  border-style: none;
}

:where(svg:not([fill])) {
  fill: currentColor;
}

svg:not(:root) {
  overflow: hidden;
}

button {
  text-transform: none;
  margin: 0;
  font-family: inherit;
  overflow: visible;
}

[type="button"], [type="reset"], [type="submit"], button {
  -webkit-appearance: button;
}

button {
  width: 100%;
  margin-bottom: var(--spacing);
  display: block;
}

[role="button"] {
  text-decoration: none;
  display: inline-block;
}

[role="button"], button, input[type="button"], input[type="reset"], input[type="submit"] {
  --background-color: var(--primary);
  --border-color: var(--primary);
  --color: var(--primary-inverse);
  --box-shadow: var(--button-box-shadow, 0 0 0 #0000);
  padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  color: var(--color);
  font-weight: var(--font-weight);
  font-size: 1rem;
  line-height: var(--line-height);
  text-align: center;
  cursor: pointer;
  transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
  outline: 0;
}

[role="button"]:is([aria-current], :hover, :active, :focus), button:is([aria-current], :hover, :active, :focus), input[type="button"]:is([aria-current], :hover, :active, :focus), input[type="reset"]:is([aria-current], :hover, :active, :focus), input[type="submit"]:is([aria-current], :hover, :active, :focus) {
  --background-color: var(--primary-hover);
  --border-color: var(--primary-hover);
  --box-shadow: var(--button-hover-box-shadow, 0 0 0 #0000);
  --color: var(--primary-inverse);
}

[role="button"]:focus, button:focus, input[type="button"]:focus, input[type="reset"]:focus, input[type="submit"]:focus {
  --box-shadow: var(--button-hover-box-shadow, 0 0 0 #0000), 0 0 0 var(--outline-width) var(--primary-focus);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).secondary, input[type="reset"] {
  --background-color: var(--secondary);
  --border-color: var(--secondary);
  --color: var(--secondary-inverse);
  cursor: pointer;
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).secondary:is([aria-current], :hover, :active, :focus), input[type="reset"]:is([aria-current], :hover, :active, :focus) {
  --background-color: var(--secondary-hover);
  --border-color: var(--secondary-hover);
  --color: var(--secondary-inverse);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).secondary:focus, input[type="reset"]:focus {
  --box-shadow: var(--button-hover-box-shadow, 0 0 0 #0000), 0 0 0 var(--outline-width) var(--secondary-focus);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).contrast {
  --background-color: var(--contrast);
  --border-color: var(--contrast);
  --color: var(--contrast-inverse);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).contrast:is([aria-current], :hover, :active, :focus) {
  --background-color: var(--contrast-hover);
  --border-color: var(--contrast-hover);
  --color: var(--contrast-inverse);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).contrast:focus {
  --box-shadow: var(--button-hover-box-shadow, 0 0 0 #0000), 0 0 0 var(--outline-width) var(--contrast-focus);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).outline, input[type="reset"].outline {
  --background-color: transparent;
  --color: var(--primary);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).outline:is([aria-current], :hover, :active, :focus), input[type="reset"].outline:is([aria-current], :hover, :active, :focus) {
  --background-color: transparent;
  --color: var(--primary-hover);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).outline.secondary, input[type="reset"].outline {
  --color: var(--secondary);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).outline.secondary:is([aria-current], :hover, :active, :focus), input[type="reset"].outline:is([aria-current], :hover, :active, :focus) {
  --color: var(--secondary-hover);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).outline.contrast {
  --color: var(--contrast);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).outline.contrast:is([aria-current], :hover, :active, :focus) {
  --color: var(--contrast-hover);
}

:where(button, [type="submit"], [type="button"], [type="reset"], [role="button"])[disabled], :where(fieldset[disabled]) :is(button, [type="submit"], [type="button"], [type="reset"], [role="button"]), a[role="button"]:not([href]) {
  opacity: .5;
  pointer-events: none;
}

input, optgroup, select, textarea {
  font-size: 1rem;
  line-height: var(--line-height);
  letter-spacing: inherit;
  margin: 0;
  font-family: inherit;
}

input {
  overflow: visible;
}

select {
  text-transform: none;
}

legend {
  max-width: 100%;
  color: inherit;
  white-space: normal;
  padding: 0;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  padding: 0;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 0;
}

:-moz-ui-invalid {
  box-shadow: none;
}

::-ms-expand {
  display: none;
}

[type="file"], [type="range"] {
  border-width: 0;
  padding: 0;
}

input:not([type="checkbox"], [type="radio"], [type="range"]) {
  height: calc(1rem * var(--line-height)  + var(--form-element-spacing-vertical) * 2 + var(--border-width) * 2);
}

fieldset {
  margin: 0;
  margin-bottom: var(--spacing);
  border: 0;
  padding: 0;
}

fieldset legend, label {
  margin-bottom: calc(var(--spacing) * .25);
  font-weight: var(--form-label-font-weight, var(--font-weight));
  display: block;
}

input:not([type="checkbox"], [type="radio"]), select, textarea {
  width: 100%;
}

input:not([type="checkbox"], [type="radio"], [type="range"], [type="file"]), select, textarea {
  -webkit-appearance: none;
  appearance: none;
  padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
}

input, select, textarea {
  --background-color: var(--form-element-background-color);
  --border-color: var(--form-element-border-color);
  --color: var(--form-element-color);
  --box-shadow: none;
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  color: var(--color);
  font-weight: var(--font-weight);
  transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
  outline: 0;
}

:where(select, textarea):is(:active, :focus), input:not([type="submit"], [type="button"], [type="reset"], [type="checkbox"], [type="radio"], [readonly]):is(:active, :focus) {
  --background-color: var(--form-element-active-background-color);
}

:where(select, textarea):is(:active, :focus), input:not([type="submit"], [type="button"], [type="reset"], [role="switch"], [readonly]):is(:active, :focus) {
  --border-color: var(--form-element-active-border-color);
}

input:not([type="submit"], [type="button"], [type="reset"], [type="range"], [type="file"], [readonly]):focus, select:focus, textarea:focus {
  --box-shadow: 0 0 0 var(--outline-width) var(--form-element-focus-color);
}

:where(fieldset[disabled]) :is(input:not([type="submit"], [type="button"], [type="reset"]), select, textarea), input:not([type="submit"], [type="button"], [type="reset"])[disabled], select[disabled], textarea[disabled] {
  --background-color: var(--form-element-disabled-background-color);
  --border-color: var(--form-element-disabled-border-color);
  opacity: var(--form-element-disabled-opacity);
  pointer-events: none;
}

:where(input, select, textarea):not([type="checkbox"], [type="radio"], [type="date"], [type="datetime-local"], [type="month"], [type="time"], [type="week"])[aria-invalid] {
  padding-left: var(--form-element-spacing-horizontal);
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 1rem;
  padding-right: calc(var(--form-element-spacing-horizontal)  + 1.5rem) !important;
  -webkit-padding-start: var(--form-element-spacing-horizontal) !important;
  -webkit-padding-end: calc(var(--form-element-spacing-horizontal)  + 1.5rem) !important;
  padding-inline-start: var(--form-element-spacing-horizontal) !important;
  padding-inline-end: calc(var(--form-element-spacing-horizontal)  + 1.5rem) !important;
}

:where(input, select, textarea):not([type="checkbox"], [type="radio"], [type="date"], [type="datetime-local"], [type="month"], [type="time"], [type="week"])[aria-invalid="false"] {
  background-image: var(--icon-valid);
}

:where(input, select, textarea):not([type="checkbox"], [type="radio"], [type="date"], [type="datetime-local"], [type="month"], [type="time"], [type="week"])[aria-invalid="true"] {
  background-image: var(--icon-invalid);
}

:where(input, select, textarea)[aria-invalid="false"] {
  --border-color: var(--form-element-valid-border-color);
}

:where(input, select, textarea)[aria-invalid="false"]:is(:active, :focus) {
  --border-color: var(--form-element-valid-active-border-color) !important;
  --box-shadow: 0 0 0 var(--outline-width) var(--form-element-valid-focus-color) !important;
}

:where(input, select, textarea)[aria-invalid="true"] {
  --border-color: var(--form-element-invalid-border-color);
}

:where(input, select, textarea)[aria-invalid="true"]:is(:active, :focus) {
  --border-color: var(--form-element-invalid-active-border-color) !important;
  --box-shadow: 0 0 0 var(--outline-width) var(--form-element-invalid-focus-color) !important;
}

[dir="rtl"] :where(input, select, textarea):not([type="checkbox"], [type="radio"]):is([aria-invalid], [aria-invalid="true"], [aria-invalid="false"]) {
  background-position: .75rem 50%;
}

input::-webkit-input-placeholder, input::placeholder, select:invalid, textarea::-webkit-input-placeholder, textarea::placeholder {
  color: var(--form-element-placeholder-color);
  opacity: 1;
}

input:not([type="checkbox"], [type="radio"]), select, textarea {
  margin-bottom: var(--spacing);
}

select::-ms-expand {
  background-color: #0000;
  border: 0;
}

select:not([multiple], [size]) {
  padding-right: calc(var(--form-element-spacing-horizontal)  + 1.5rem);
  padding-left: var(--form-element-spacing-horizontal);
  -webkit-padding-start: var(--form-element-spacing-horizontal);
  -webkit-padding-end: calc(var(--form-element-spacing-horizontal)  + 1.5rem);
  background-image: var(--icon-chevron);
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 1rem;
  padding-inline-start: var(--form-element-spacing-horizontal);
  padding-inline-end: calc(var(--form-element-spacing-horizontal)  + 1.5rem);
}

[dir="rtl"] select:not([multiple], [size]) {
  background-position: .75rem 50%;
}

:where(input, select, textarea, .grid) + small {
  width: 100%;
  margin-top: calc(var(--spacing) * -.75);
  margin-bottom: var(--spacing);
  color: var(--muted-color);
  display: block;
}

label > :where(input, select, textarea) {
  margin-top: calc(var(--spacing) * .25);
}

[type="checkbox"], [type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  width: 1.25em;
  height: 1.25em;
  -webkit-margin-start: 0;
  margin-top: -.125em;
  margin-left: 0;
  margin-right: .375em;
  -webkit-margin-end: .375em;
  border-width: var(--border-width);
  font-size: inherit;
  vertical-align: middle;
  cursor: pointer;
  margin-inline: 0 .375em;
}

[type="checkbox"]::-ms-check, [type="radio"]::-ms-check {
  display: none;
}

[type="checkbox"]:checked, [type="checkbox"]:checked:active, [type="checkbox"]:checked:focus, [type="radio"]:checked, [type="radio"]:checked:active, [type="radio"]:checked:focus {
  --background-color: var(--primary);
  --border-color: var(--primary);
  background-image: var(--icon-checkbox);
  background-position: center;
  background-repeat: no-repeat;
  background-size: .75em;
}

[type="checkbox"] ~ label, [type="radio"] ~ label {
  cursor: pointer;
  margin-bottom: 0;
  margin-right: .375em;
  display: inline-block;
}

[type="checkbox"]:indeterminate {
  --background-color: var(--primary);
  --border-color: var(--primary);
  background-image: var(--icon-minus);
  background-position: center;
  background-repeat: no-repeat;
  background-size: .75em;
}

[type="radio"] {
  border-radius: 50%;
}

[type="radio"]:checked, [type="radio"]:checked:active, [type="radio"]:checked:focus {
  --background-color: var(--primary-inverse);
  background-image: none;
  border-width: .35em;
}

[type="checkbox"][role="switch"] {
  --background-color: var(--switch-background-color);
  --border-color: var(--switch-background-color);
  --color: var(--switch-color);
  width: 2.25em;
  height: 1.25em;
  border: var(--border-width) solid var(--border-color);
  background-color: var(--background-color);
  border-radius: 1.25em;
  line-height: 1.25em;
}

[type="checkbox"][role="switch"]:focus {
  --background-color: var(--switch-background-color);
  --border-color: var(--switch-background-color);
}

[type="checkbox"][role="switch"]:checked {
  --background-color: var(--switch-checked-background-color);
  --border-color: var(--switch-checked-background-color);
}

[type="checkbox"][role="switch"]:before {
  width: calc(1.25em - (var(--border-width) * 2));
  height: 100%;
  background-color: var(--color);
  content: "";
  border-radius: 50%;
  transition: margin .1s ease-in-out;
  display: block;
}

[type="checkbox"][role="switch"]:checked {
  background-image: none;
}

[type="checkbox"][role="switch"]:checked:before {
  margin-left: calc(1.125em - var(--border-width));
  -webkit-margin-start: calc(1.125em - var(--border-width));
  margin-inline-start: calc(1.125em - var(--border-width));
}

[type="checkbox"]:checked[aria-invalid="false"], [type="checkbox"][aria-invalid="false"], [type="checkbox"][role="switch"]:checked[aria-invalid="false"], [type="checkbox"][role="switch"][aria-invalid="false"], [type="radio"]:checked[aria-invalid="false"], [type="radio"][aria-invalid="false"] {
  --border-color: var(--form-element-valid-border-color);
}

[type="checkbox"]:checked[aria-invalid="true"], [type="checkbox"][aria-invalid="true"], [type="checkbox"][role="switch"]:checked[aria-invalid="true"], [type="checkbox"][role="switch"][aria-invalid="true"], [type="radio"]:checked[aria-invalid="true"], [type="radio"][aria-invalid="true"] {
  --border-color: var(--form-element-invalid-border-color);
}

[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

[type="color"]::-moz-focus-inner {
  padding: 0;
}

[type="color"]::-webkit-color-swatch {
  border-radius: calc(var(--border-radius) * .5);
  border: 0;
}

[type="color"]::-moz-color-swatch {
  border-radius: calc(var(--border-radius) * .5);
  border: 0;
}

input:not([type="checkbox"], [type="radio"], [type="range"], [type="file"]):is([type="date"], [type="datetime-local"], [type="month"], [type="time"], [type="week"]) {
  --icon-position: .75rem;
  --icon-width: 1rem;
  padding-right: calc(var(--icon-width)  + var(--icon-position));
  background-image: var(--icon-date);
  background-position: center right var(--icon-position);
  background-size: var(--icon-width) auto;
  background-repeat: no-repeat;
}

input:not([type="checkbox"], [type="radio"], [type="range"], [type="file"])[type="time"] {
  background-image: var(--icon-time);
}

[type="date"]::-webkit-calendar-picker-indicator, [type="datetime-local"]::-webkit-calendar-picker-indicator, [type="month"]::-webkit-calendar-picker-indicator, [type="time"]::-webkit-calendar-picker-indicator, [type="week"]::-webkit-calendar-picker-indicator {
  width: var(--icon-width);
  margin-right: calc(var(--icon-width) * -1);
  margin-left: var(--icon-position);
  opacity: 0;
}

[dir="rtl"] :is([type="date"], [type="datetime-local"], [type="month"], [type="time"], [type="week"]) {
  text-align: right;
}

@-moz-document url-prefix() {
  [type="date"], [type="datetime-local"], [type="month"], [type="time"], [type="week"] {
    padding-right: var(--form-element-spacing-horizontal) !important;
    background-image: none !important;
  }
}

[type="file"] {
  --color: var(--muted-color);
  padding: calc(var(--form-element-spacing-vertical) * .5) 0;
  background: none;
  border: 0;
  border-radius: 0;
}

[type="file"]::file-selector-button {
  --background-color: var(--secondary);
  --border-color: var(--secondary);
  --color: var(--secondary-inverse);
  margin-right: calc(var(--spacing) / 2);
  -webkit-margin-start: 0;
  margin-left: 0;
  -webkit-margin-end: calc(var(--spacing) / 2);
  padding: calc(var(--form-element-spacing-vertical) * .5) calc(var(--form-element-spacing-horizontal) * .5);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  color: var(--color);
  font-weight: var(--font-weight);
  font-size: 1rem;
  line-height: var(--line-height);
  text-align: center;
  cursor: pointer;
  transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
  outline: 0;
  margin-inline-start: 0;
  margin-inline-end: calc(var(--spacing) / 2);
}

[type="file"]::file-selector-button:is(:hover, :active, :focus) {
  --background-color: var(--secondary-hover);
  --border-color: var(--secondary-hover);
}

[type="file"]::-webkit-file-upload-button {
  --background-color: var(--secondary);
  --border-color: var(--secondary);
  --color: var(--secondary-inverse);
  margin-right: calc(var(--spacing) / 2);
  -webkit-margin-start: 0;
  margin-left: 0;
  -webkit-margin-end: calc(var(--spacing) / 2);
  padding: calc(var(--form-element-spacing-vertical) * .5) calc(var(--form-element-spacing-horizontal) * .5);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  color: var(--color);
  font-weight: var(--font-weight);
  font-size: 1rem;
  line-height: var(--line-height);
  text-align: center;
  cursor: pointer;
  -webkit-transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
  transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
  outline: 0;
  margin-inline-start: 0;
  margin-inline-end: calc(var(--spacing) / 2);
}

[type="file"]::-webkit-file-upload-button:is(:hover, :active, :focus) {
  --background-color: var(--secondary-hover);
  --border-color: var(--secondary-hover);
}

[type="file"]::-ms-browse {
  --background-color: var(--secondary);
  --border-color: var(--secondary);
  --color: var(--secondary-inverse);
  margin-right: calc(var(--spacing) / 2);
  margin-left: 0;
  padding: calc(var(--form-element-spacing-vertical) * .5) calc(var(--form-element-spacing-horizontal) * .5);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  color: var(--color);
  font-weight: var(--font-weight);
  font-size: 1rem;
  line-height: var(--line-height);
  text-align: center;
  cursor: pointer;
  -ms-transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
  transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
  outline: 0;
  margin-inline-start: 0;
  margin-inline-end: calc(var(--spacing) / 2);
}

[type="file"]::-ms-browse:is(:hover, :active, :focus) {
  --background-color: var(--secondary-hover);
  --border-color: var(--secondary-hover);
}

[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 1.25rem;
  background: none;
}

[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: .25rem;
  border-radius: var(--border-radius);
  background-color: var(--range-border-color);
  -webkit-transition: background-color var(--transition), box-shadow var(--transition);
  transition: background-color var(--transition), box-shadow var(--transition);
}

[type="range"]::-moz-range-track {
  width: 100%;
  height: .25rem;
  border-radius: var(--border-radius);
  background-color: var(--range-border-color);
  -moz-transition: background-color var(--transition), box-shadow var(--transition);
  transition: background-color var(--transition), box-shadow var(--transition);
}

[type="range"]::-ms-track {
  width: 100%;
  height: .25rem;
  border-radius: var(--border-radius);
  background-color: var(--range-border-color);
  -ms-transition: background-color var(--transition), box-shadow var(--transition);
  transition: background-color var(--transition), box-shadow var(--transition);
}

[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid var(--range-thumb-border-color);
  background-color: var(--range-thumb-color);
  cursor: pointer;
  -webkit-transition: background-color var(--transition), transform var(--transition);
  transition: background-color var(--transition), transform var(--transition);
  border-radius: 50%;
  margin-top: -.5rem;
}

[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid var(--range-thumb-border-color);
  background-color: var(--range-thumb-color);
  cursor: pointer;
  -moz-transition: background-color var(--transition), transform var(--transition);
  transition: background-color var(--transition), transform var(--transition);
  border-radius: 50%;
  margin-top: -.5rem;
}

[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid var(--range-thumb-border-color);
  background-color: var(--range-thumb-color);
  cursor: pointer;
  -ms-transition: background-color var(--transition), transform var(--transition);
  transition: background-color var(--transition), transform var(--transition);
  border-radius: 50%;
  margin-top: -.5rem;
}

[type="range"]:focus, [type="range"]:hover {
  --range-border-color: var(--range-active-border-color);
  --range-thumb-color: var(--range-thumb-hover-color);
}

[type="range"]:active {
  --range-thumb-color: var(--range-thumb-active-color);
}

[type="range"]:active::-webkit-slider-thumb {
  transform: scale(1.25);
}

[type="range"]:active::-moz-range-thumb {
  transform: scale(1.25);
}

[type="range"]:active::-ms-thumb {
  transform: scale(1.25);
}

input:not([type="checkbox"], [type="radio"], [type="range"], [type="file"])[type="search"] {
  -webkit-padding-start: calc(var(--form-element-spacing-horizontal)  + 1.75rem);
  background-image: var(--icon-search);
  background-position: 1.125rem 50%;
  background-repeat: no-repeat;
  background-size: 1rem;
  border-radius: 5rem;
  padding-inline-start: calc(var(--form-element-spacing-horizontal)  + 1.75rem);
}

input:not([type="checkbox"], [type="radio"], [type="range"], [type="file"])[type="search"][aria-invalid] {
  background-position: 1.125rem 50%, right .75rem center;
  -webkit-padding-start: calc(var(--form-element-spacing-horizontal)  + 1.75rem) !important;
  padding-inline-start: calc(var(--form-element-spacing-horizontal)  + 1.75rem) !important;
}

input:not([type="checkbox"], [type="radio"], [type="range"], [type="file"])[type="search"][aria-invalid="false"] {
  background-image: var(--icon-search), var(--icon-valid);
}

input:not([type="checkbox"], [type="radio"], [type="range"], [type="file"])[type="search"][aria-invalid="true"] {
  background-image: var(--icon-search), var(--icon-invalid);
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: none;
}

[dir="rtl"] :where(input):not([type="checkbox"], [type="radio"], [type="range"], [type="file"])[type="search"] {
  background-position: right 1.125rem center;
}

[dir="rtl"] :where(input):not([type="checkbox"], [type="radio"], [type="range"], [type="file"])[type="search"][aria-invalid] {
  background-position: right 1.125rem center, .75rem 50%;
}

:where(table) {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  text-indent: 0;
}

td, th {
  padding: calc(var(--spacing) / 2) var(--spacing);
  border-bottom: var(--border-width) solid var(--table-border-color);
  color: var(--color);
  font-weight: var(--font-weight);
  font-size: var(--font-size);
  text-align: left;
  text-align: start;
}

tfoot td, tfoot th {
  border-top: var(--border-width) solid var(--table-border-color);
  border-bottom: 0;
}

table[role="grid"] tbody tr:nth-child(odd) {
  background-color: var(--table-row-stripped-background-color);
}

code, kbd, pre, samp {
  font-size: .875em;
  font-family: var(--font-family);
}

pre {
  -ms-overflow-style: scrollbar;
  overflow: auto;
}

code, kbd, pre {
  border-radius: var(--border-radius);
  background: var(--code-background-color);
  color: var(--code-color);
  font-weight: var(--font-weight);
  line-height: initial;
}

code, kbd {
  padding: .375rem .5rem;
  display: inline-block;
}

pre {
  margin-bottom: var(--spacing);
  display: block;
  overflow-x: auto;
}

pre > code {
  padding: var(--spacing);
  font-size: 14px;
  line-height: var(--line-height);
  background: none;
  display: block;
}

code b {
  color: var(--code-tag-color);
  font-weight: var(--font-weight);
}

code i {
  color: var(--code-property-color);
  font-style: normal;
}

code u {
  color: var(--code-value-color);
  text-decoration: none;
}

code em {
  color: var(--code-comment-color);
  font-style: normal;
}

kbd {
  background-color: var(--code-kbd-background-color);
  color: var(--code-kbd-color);
  vertical-align: baseline;
}

hr {
  height: 0;
  border: 0;
  border-top: 1px solid var(--muted-border-color);
  color: inherit;
}

[hidden], template {
  display: none !important;
}

canvas {
  display: inline-block;
}

details {
  margin-bottom: var(--spacing);
  padding-bottom: var(--spacing);
  border-bottom: var(--border-width) solid var(--accordion-border-color);
  display: block;
}

details summary {
  cursor: pointer;
  transition: color var(--transition);
  line-height: 1rem;
  list-style-type: none;
}

details summary:not([role]) {
  color: var(--accordion-close-summary-color);
}

details summary::-webkit-details-marker {
  display: none;
}

details summary::marker {
  display: none;
}

details summary::-moz-list-bullet {
  list-style-type: none;
}

details summary:after {
  width: 1rem;
  height: 1rem;
  -webkit-margin-start: calc(var(--spacing, 1rem) * .5);
  float: right;
  background-image: var(--icon-chevron);
  content: "";
  transition: transform var(--transition);
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 1rem;
  margin-inline-start: calc(var(--spacing, 1rem) * .5);
  display: block;
  transform: rotate(-90deg);
}

details summary:focus {
  outline: 0;
}

details summary:focus:not([role="button"]) {
  color: var(--accordion-active-summary-color);
}

details summary[role="button"] {
  width: 100%;
  text-align: left;
}

details summary[role="button"]:after {
  height: calc(1rem * var(--line-height, 1.5));
  background-image: var(--icon-chevron-button);
}

details summary[role="button"]:not(.outline).contrast:after {
  background-image: var(--icon-chevron-button-inverse);
}

details[open] > summary {
  margin-bottom: calc(var(--spacing));
}

details[open] > summary:not([role]):not(:focus) {
  color: var(--accordion-open-summary-color);
}

details[open] > summary:after {
  transform: rotate(0);
}

[dir="rtl"] details summary {
  text-align: right;
}

[dir="rtl"] details summary:after {
  float: left;
  background-position: 0;
}

article {
  margin: var(--block-spacing-vertical) 0;
  padding: var(--block-spacing-vertical) var(--block-spacing-horizontal);
  border-radius: var(--border-radius);
  background: var(--card-background-color);
  box-shadow: var(--card-box-shadow);
}

article > footer, article > header {
  margin-right: calc(var(--block-spacing-horizontal) * -1);
  margin-left: calc(var(--block-spacing-horizontal) * -1);
  padding: calc(var(--block-spacing-vertical) * .66) var(--block-spacing-horizontal);
  background-color: var(--card-sectionning-background-color);
}

article > header {
  margin-top: calc(var(--block-spacing-vertical) * -1);
  margin-bottom: var(--block-spacing-vertical);
  border-bottom: var(--border-width) solid var(--card-border-color);
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
}

article > footer {
  margin-top: var(--block-spacing-vertical);
  margin-bottom: calc(var(--block-spacing-vertical) * -1);
  border-top: var(--border-width) solid var(--card-border-color);
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

:root {
  --scrollbar-width: 0px;
}

dialog {
  z-index: 999;
  width: inherit;
  min-width: 100%;
  height: inherit;
  min-height: 100%;
  padding: var(--spacing);
  -webkit-backdrop-filter: var(--modal-overlay-backdrop-filter);
  -webkit-backdrop-filter: var(--modal-overlay-backdrop-filter);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  background-color: var(--modal-overlay-background-color);
  color: var(--color);
  border: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

dialog article {
  max-height: calc(100vh - var(--spacing) * 2);
  overflow: auto;
}

@media (min-width: 576px) {
  dialog article {
    max-width: 510px;
  }
}

@media (min-width: 768px) {
  dialog article {
    max-width: 700px;
  }
}

dialog article > footer, dialog article > header {
  padding: calc(var(--block-spacing-vertical) * .5) var(--block-spacing-horizontal);
}

dialog article > header .close {
  margin: 0;
  margin-left: var(--spacing);
  float: right;
}

dialog article > footer {
  text-align: right;
}

dialog article > footer [role="button"] {
  margin-bottom: 0;
}

dialog article > footer [role="button"]:not(:first-of-type) {
  margin-left: calc(var(--spacing) * .5);
}

dialog article p:last-of-type {
  margin: 0;
}

dialog article .close {
  width: 1rem;
  height: 1rem;
  margin-top: calc(var(--block-spacing-vertical) * -.5);
  margin-bottom: var(--typography-spacing-vertical);
  background-image: var(--icon-close);
  opacity: .5;
  transition: opacity var(--transition);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 1rem;
  margin-left: auto;
  display: block;
}

dialog article .close:is([aria-current], :hover, :active, :focus) {
  opacity: 1;
}

dialog:not([open]), dialog[open="false"] {
  display: none;
}

.modal-is-open {
  padding-right: var(--scrollbar-width, 0);
  pointer-events: none;
  touch-action: none;
  overflow: hidden;
}

.modal-is-open dialog {
  pointer-events: auto;
}

:where(.modal-is-opening, .modal-is-closing) dialog, :where(.modal-is-opening, .modal-is-closing) dialog > article {
  animation-duration: .2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
}

:where(.modal-is-opening, .modal-is-closing) dialog {
  animation-name: modal-overlay;
  animation-duration: .8s;
}

:where(.modal-is-opening, .modal-is-closing) dialog > article {
  animation-name: modal;
  animation-delay: .2s;
}

.modal-is-closing dialog, .modal-is-closing dialog > article {
  animation-direction: reverse;
  animation-delay: 0s;
}

@keyframes modal-overlay {
  from {
    -webkit-backdrop-filter: none;
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
    background-color: #0000;
  }
}

@keyframes modal {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
}

:where(nav li):before {
  float: left;
  content: "​";
}

nav, nav ul {
  display: flex;
}

nav {
  justify-content: space-between;
}

nav ol, nav ul {
  align-items: center;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

nav ol:first-of-type, nav ul:first-of-type {
  margin-left: calc(var(--nav-element-spacing-horizontal) * -1);
}

nav ol:last-of-type, nav ul:last-of-type {
  margin-right: calc(var(--nav-element-spacing-horizontal) * -1);
}

nav li {
  padding: var(--nav-element-spacing-vertical) var(--nav-element-spacing-horizontal);
  margin: 0;
  display: inline-block;
}

nav li > * {
  --spacing: 0;
}

nav :where(a, [role="link"]) {
  margin: calc(var(--nav-link-spacing-vertical) * -1) calc(var(--nav-link-spacing-horizontal) * -1);
  padding: var(--nav-link-spacing-vertical) var(--nav-link-spacing-horizontal);
  border-radius: var(--border-radius);
  text-decoration: none;
  display: inline-block;
}

nav :where(a, [role="link"]):is([aria-current], :hover, :active, :focus) {
  text-decoration: none;
}

nav[aria-label="breadcrumb"] {
  justify-content: start;
  align-items: center;
}

nav[aria-label="breadcrumb"] ul li:not(:first-child) {
  -webkit-margin-start: var(--nav-link-spacing-horizontal);
  margin-inline-start: var(--nav-link-spacing-horizontal);
}

nav[aria-label="breadcrumb"] ul li:not(:last-child) :after {
  width: calc(var(--nav-link-spacing-horizontal) * 2);
  -webkit-margin-start: calc(var(--nav-link-spacing-horizontal) / 2);
  content: "/";
  color: var(--muted-color);
  text-align: center;
  margin-inline-start: calc(var(--nav-link-spacing-horizontal) / 2);
  position: absolute;
}

nav[aria-label="breadcrumb"] a[aria-current] {
  color: inherit;
  pointer-events: none;
  background-color: #0000;
  text-decoration: none;
}

nav [role="button"] {
  margin-right: inherit;
  margin-left: inherit;
  padding: var(--nav-link-spacing-vertical) var(--nav-link-spacing-horizontal);
}

aside li, aside nav, aside ol, aside ul {
  display: block;
}

aside li {
  padding: calc(var(--nav-element-spacing-vertical) * .5) var(--nav-element-spacing-horizontal);
}

aside li a {
  display: block;
}

aside li [role="button"] {
  margin: inherit;
}

[dir="rtl"] nav[aria-label="breadcrumb"] ul li:not(:last-child) :after {
  content: "\\";
}

progress {
  vertical-align: baseline;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: .5rem;
  margin-bottom: calc(var(--spacing) * .5);
  border-radius: var(--border-radius);
  background-color: var(--progress-background-color);
  color: var(--progress-color);
  border: 0;
  display: inline-block;
  overflow: hidden;
}

progress::-webkit-progress-bar {
  border-radius: var(--border-radius);
  background: none;
}

progress[value]::-webkit-progress-value {
  background-color: var(--progress-color);
}

progress::-moz-progress-bar {
  background-color: var(--progress-color);
}

@media (prefers-reduced-motion: no-preference) {
  progress:indeterminate {
    background: var(--progress-background-color) linear-gradient(to right, var(--progress-color) 30%, var(--progress-background-color) 30%) top left / 150% 150% no-repeat;
    animation: 1s linear infinite progress-indeterminate;
  }

  progress:indeterminate[value]::-webkit-progress-value {
    background-color: #0000;
  }

  progress:indeterminate::-moz-progress-bar {
    background-color: #0000;
  }

  [dir="rtl"] progress:indeterminate {
    animation-direction: reverse;
  }
}

@keyframes progress-indeterminate {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

details[role="list"], li[role="list"] {
  position: relative;
}

details[role="list"] summary + ul, li[role="list"] > ul {
  z-index: 99;
  border: var(--border-width) solid var(--dropdown-border-color);
  border-radius: var(--border-radius);
  background-color: var(--dropdown-background-color);
  box-shadow: var(--card-box-shadow);
  color: var(--dropdown-color);
  white-space: nowrap;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  flex-direction: column;
  margin: 0;
  padding: 0;
  display: flex;
  position: absolute;
  top: auto;
  left: 0;
  right: 0;
}

details[role="list"] summary + ul li, li[role="list"] > ul li {
  width: 100%;
  padding: calc(var(--form-element-spacing-vertical) * .5) var(--form-element-spacing-horizontal);
  margin-bottom: 0;
  list-style: none;
}

details[role="list"] summary + ul li:first-of-type, li[role="list"] > ul li:first-of-type {
  margin-top: calc(var(--form-element-spacing-vertical) * .5);
}

details[role="list"] summary + ul li:last-of-type, li[role="list"] > ul li:last-of-type {
  margin-bottom: calc(var(--form-element-spacing-vertical) * .5);
}

details[role="list"] summary + ul li a, li[role="list"] > ul li a {
  margin: calc(var(--form-element-spacing-vertical) * -.5) calc(var(--form-element-spacing-horizontal) * -1);
  padding: calc(var(--form-element-spacing-vertical) * .5) var(--form-element-spacing-horizontal);
  color: var(--dropdown-color);
  text-overflow: ellipsis;
  text-decoration: none;
  display: block;
  overflow: hidden;
}

details[role="list"] summary + ul li a:hover, li[role="list"] > ul li a:hover {
  background-color: var(--dropdown-hover-background-color);
}

details[role="list"] summary:after, li[role="list"] > a:after {
  width: 1rem;
  height: calc(1rem * var(--line-height, 1.5));
  -webkit-margin-start: .5rem;
  float: right;
  content: "";
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 1rem;
  margin-inline-start: .5rem;
  display: block;
  transform: rotate(0);
}

details[role="list"] {
  border-bottom: none;
  padding: 0;
}

details[role="list"] summary {
  margin-bottom: 0;
}

details[role="list"] summary:not([role]) {
  height: calc(1rem * var(--line-height)  + var(--form-element-spacing-vertical) * 2 + var(--border-width) * 2);
  padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
  border: var(--border-width) solid var(--form-element-border-color);
  border-radius: var(--border-radius);
  background-color: var(--form-element-background-color);
  color: var(--form-element-placeholder-color);
  line-height: inherit;
  cursor: pointer;
  transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
}

details[role="list"] summary:not([role]):active, details[role="list"] summary:not([role]):focus {
  border-color: var(--form-element-active-border-color);
  background-color: var(--form-element-active-background-color);
}

details[role="list"] summary:not([role]):focus {
  box-shadow: 0 0 0 var(--outline-width) var(--form-element-focus-color);
}

details[role="list"][open] summary {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

details[role="list"][open] summary:before {
  z-index: 1;
  content: "";
  cursor: default;
  background: none;
  display: block;
  position: fixed;
  inset: 0;
}

nav details[role="list"] summary, nav li[role="list"] a {
  direction: ltr;
  display: flex;
}

nav details[role="list"] summary + ul, nav li[role="list"] > ul {
  min-width: -moz-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  border-radius: var(--border-radius);
}

nav details[role="list"] summary + ul li a, nav li[role="list"] > ul li a {
  border-radius: 0;
}

nav details[role="list"] summary, nav details[role="list"] summary:not([role]) {
  height: auto;
  padding: var(--nav-link-spacing-vertical) var(--nav-link-spacing-horizontal);
}

nav details[role="list"][open] summary {
  border-radius: var(--border-radius);
}

nav details[role="list"] summary + ul {
  margin-top: var(--outline-width);
  -webkit-margin-start: 0;
  margin-inline-start: 0;
}

nav details[role="list"] summary[role="link"] {
  margin-bottom: calc(var(--nav-link-spacing-vertical) * -1);
  line-height: var(--line-height);
}

nav details[role="list"] summary[role="link"] + ul {
  margin-top: calc(var(--nav-link-spacing-vertical)  + var(--outline-width));
  -webkit-margin-start: calc(var(--nav-link-spacing-horizontal) * -1);
  margin-inline-start: calc(var(--nav-link-spacing-horizontal) * -1);
}

li[role="list"] a:active ~ ul, li[role="list"] a:focus ~ ul, li[role="list"]:hover > ul {
  display: flex;
}

li[role="list"] > ul {
  margin-top: calc(var(--nav-link-spacing-vertical)  + var(--outline-width));
  -webkit-margin-start: calc(var(--nav-element-spacing-horizontal)  - var(--nav-link-spacing-horizontal));
  margin-inline-start: calc(var(--nav-element-spacing-horizontal)  - var(--nav-link-spacing-horizontal));
  display: none;
}

li[role="list"] > a:after {
  background-image: var(--icon-chevron);
}

label > details[role="list"] {
  margin-top: calc(var(--spacing) * .25);
  margin-bottom: var(--spacing);
}

[aria-busy="true"] {
  cursor: progress;
}

[aria-busy="true"]:not(input, select, textarea, html):before {
  width: 1em;
  height: 1em;
  content: "";
  vertical-align: text-bottom;
  vertical-align: -.125em;
  opacity: var(--loading-spinner-opacity);
  border: .1875em solid;
  border-right-color: #0000;
  border-radius: 1em;
  animation: .75s linear infinite spinner;
  display: inline-block;
}

[aria-busy="true"]:not(input, select, textarea, html):not(:empty):before {
  margin-right: calc(var(--spacing) * .5);
  -webkit-margin-start: 0;
  margin-left: 0;
  -webkit-margin-end: calc(var(--spacing) * .5);
  margin-inline-start: 0;
  margin-inline-end: calc(var(--spacing) * .5);
}

[aria-busy="true"]:not(input, select, textarea, html):empty {
  text-align: center;
}

a[aria-busy="true"], button[aria-busy="true"], input[type="button"][aria-busy="true"], input[type="reset"][aria-busy="true"], input[type="submit"][aria-busy="true"] {
  pointer-events: none;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

[data-tooltip] {
  position: relative;
}

[data-tooltip]:not(a, button, input) {
  cursor: help;
  border-bottom: 1px dotted;
  text-decoration: none;
}

[data-tooltip]:after, [data-tooltip]:before, [data-tooltip][data-placement="top"]:after, [data-tooltip][data-placement="top"]:before {
  z-index: 99;
  border-radius: var(--border-radius);
  background: var(--tooltip-background-color);
  content: attr(data-tooltip);
  color: var(--tooltip-color);
  font-style: normal;
  font-weight: var(--font-weight);
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  padding: .25rem .5rem;
  font-size: .875rem;
  text-decoration: none;
  display: block;
  position: absolute;
  bottom: 100%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -.25rem);
}

[data-tooltip]:after, [data-tooltip][data-placement="top"]:after {
  content: "";
  color: var(--tooltip-background-color);
  background-color: #0000;
  border-top: .3rem solid;
  border-left: .3rem solid #0000;
  border-right: .3rem solid #0000;
  border-radius: 0;
  padding: 0;
  transform: translate(-50%);
}

[data-tooltip][data-placement="bottom"]:after, [data-tooltip][data-placement="bottom"]:before {
  top: 100%;
  bottom: auto;
  transform: translate(-50%, .25rem);
}

[data-tooltip][data-placement="bottom"]:after {
  border: .3rem solid #0000;
  border-bottom-color: currentColor;
  transform: translate(-50%, -.3rem);
}

[data-tooltip][data-placement="left"]:after, [data-tooltip][data-placement="left"]:before {
  inset: 50% 100% auto auto;
  transform: translate(-.25rem, -50%);
}

[data-tooltip][data-placement="left"]:after {
  border: .3rem solid #0000;
  border-left-color: currentColor;
  transform: translate(.3rem, -50%);
}

[data-tooltip][data-placement="right"]:after, [data-tooltip][data-placement="right"]:before {
  inset: 50% auto auto 100%;
  transform: translate(.25rem, -50%);
}

[data-tooltip][data-placement="right"]:after {
  border: .3rem solid #0000;
  border-right-color: currentColor;
  transform: translate(-.3rem, -50%);
}

[data-tooltip]:focus:after, [data-tooltip]:focus:before, [data-tooltip]:hover:after, [data-tooltip]:hover:before {
  opacity: 1;
}

@media (hover: hover) and (pointer: fine) {
  [data-tooltip]:hover:after, [data-tooltip]:hover:before, [data-tooltip][data-placement="bottom"]:focus:after, [data-tooltip][data-placement="bottom"]:focus:before, [data-tooltip][data-placement="bottom"]:hover [data-tooltip]:focus:after, [data-tooltip][data-placement="bottom"]:hover [data-tooltip]:focus:before {
    animation-name: tooltip-slide-top;
    animation-duration: .2s;
  }

  [data-tooltip]:hover:after, [data-tooltip][data-placement="bottom"]:focus:after, [data-tooltip][data-placement="bottom"]:hover [data-tooltip]:focus:after {
    animation-name: tooltip-caret-slide-top;
  }

  [data-tooltip][data-placement="bottom"]:focus:after, [data-tooltip][data-placement="bottom"]:focus:before, [data-tooltip][data-placement="bottom"]:hover:after, [data-tooltip][data-placement="bottom"]:hover:before {
    animation-name: tooltip-slide-bottom;
    animation-duration: .2s;
  }

  [data-tooltip][data-placement="bottom"]:focus:after, [data-tooltip][data-placement="bottom"]:hover:after {
    animation-name: tooltip-caret-slide-bottom;
  }

  [data-tooltip][data-placement="left"]:focus:after, [data-tooltip][data-placement="left"]:focus:before, [data-tooltip][data-placement="left"]:hover:after, [data-tooltip][data-placement="left"]:hover:before {
    animation-name: tooltip-slide-left;
    animation-duration: .2s;
  }

  [data-tooltip][data-placement="left"]:focus:after, [data-tooltip][data-placement="left"]:hover:after {
    animation-name: tooltip-caret-slide-left;
  }

  [data-tooltip][data-placement="right"]:focus:after, [data-tooltip][data-placement="right"]:focus:before, [data-tooltip][data-placement="right"]:hover:after, [data-tooltip][data-placement="right"]:hover:before {
    animation-name: tooltip-slide-right;
    animation-duration: .2s;
  }

  [data-tooltip][data-placement="right"]:focus:after, [data-tooltip][data-placement="right"]:hover:after {
    animation-name: tooltip-caret-slide-right;
  }
}

@keyframes tooltip-slide-top {
  from {
    opacity: 0;
    transform: translate(-50%, .75rem);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -.25rem);
  }
}

@keyframes tooltip-caret-slide-top {
  from {
    opacity: 0;
  }

  50% {
    opacity: 0;
    transform: translate(-50%, -.25rem);
  }

  to {
    opacity: 1;
    transform: translate(-50%);
  }
}

@keyframes tooltip-slide-bottom {
  from {
    opacity: 0;
    transform: translate(-50%, -.75rem);
  }

  to {
    opacity: 1;
    transform: translate(-50%, .25rem);
  }
}

@keyframes tooltip-caret-slide-bottom {
  from {
    opacity: 0;
  }

  50% {
    opacity: 0;
    transform: translate(-50%, -.5rem);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -.3rem);
  }
}

@keyframes tooltip-slide-left {
  from {
    opacity: 0;
    transform: translate(.75rem, -50%);
  }

  to {
    opacity: 1;
    transform: translate(-.25rem, -50%);
  }
}

@keyframes tooltip-caret-slide-left {
  from {
    opacity: 0;
  }

  50% {
    opacity: 0;
    transform: translate(.05rem, -50%);
  }

  to {
    opacity: 1;
    transform: translate(.3rem, -50%);
  }
}

@keyframes tooltip-slide-right {
  from {
    opacity: 0;
    transform: translate(-.75rem, -50%);
  }

  to {
    opacity: 1;
    transform: translate(.25rem, -50%);
  }
}

@keyframes tooltip-caret-slide-right {
  from {
    opacity: 0;
  }

  50% {
    opacity: 0;
    transform: translate(-.05rem, -50%);
  }

  to {
    opacity: 1;
    transform: translate(-.3rem, -50%);
  }
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled="true"], [disabled] {
  cursor: not-allowed;
}

[aria-hidden="false"][hidden] {
  display: initial;
}

[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

[tabindex], a, area, button, input, label, select, summary, textarea {
  -ms-touch-action: manipulation;
}

[dir="rtl"] {
  direction: rtl;
}

@media (prefers-reduced-motion: reduce) {
  :not([aria-busy="true"]), :not([aria-busy="true"]):after, :not([aria-busy="true"]):before {
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    animation-delay: -1ms !important;
  }
}

@font-face {
  font-family: FontAwesome;
  src: url("fontawesome-webfont.3981e506.eot");
  src: url("fontawesome-webfont.3981e506.eot#iefix&v=4.7.0") format("embedded-opentype"), url("fontawesome-webfont.58488e7e.woff2") format("woff2"), url("fontawesome-webfont.ed962b83.woff") format("woff"), url("fontawesome-webfont.0caf0c90.ttf") format("truetype"), url("fontawesome-webfont.a9323ae9.svg#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

.fa {
  font: 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
}

.fa-lg {
  vertical-align: -15%;
  font-size: 1.33333em;
  line-height: .75em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-fw {
  width: 1.28571em;
  text-align: center;
}

.fa-ul {
  margin-left: 2.14286em;
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  width: 2.14286em;
  text-align: center;
  position: absolute;
  top: .142857em;
  left: -2.14286em;
}

.fa-li.fa-lg {
  left: -1.85714em;
}

.fa-border {
  border: .08em solid #eee;
  border-radius: .1em;
  padding: .2em .25em .15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left {
  margin-right: .3em;
}

.fa.fa-pull-right {
  margin-left: .3em;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.fa.pull-left {
  margin-right: .3em;
}

.fa.pull-right {
  margin-left: .3em;
}

.fa-spin {
  animation: 2s linear infinite fa-spin;
}

.fa-pulse {
  animation: 1s steps(8, end) infinite fa-spin;
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(359deg);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1);
}

:root .fa-rotate-90, :root .fa-rotate-180, :root .fa-rotate-270, :root .fa-flip-horizontal, :root .fa-flip-vertical {
  filter: none;
}

.fa-stack {
  width: 2em;
  height: 2em;
  vertical-align: middle;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

.fa-glass:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-search:before {
  content: "";
}

.fa-envelope-o:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-star-o:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-th-large:before {
  content: "";
}

.fa-th:before {
  content: "";
}

.fa-th-list:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-remove:before, .fa-close:before, .fa-times:before {
  content: "";
}

.fa-search-plus:before {
  content: "";
}

.fa-search-minus:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-signal:before {
  content: "";
}

.fa-gear:before, .fa-cog:before {
  content: "";
}

.fa-trash-o:before {
  content: "";
}

.fa-home:before {
  content: "";
}

.fa-file-o:before {
  content: "";
}

.fa-clock-o:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-arrow-circle-o-down:before {
  content: "";
}

.fa-arrow-circle-o-up:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-play-circle-o:before {
  content: "";
}

.fa-rotate-right:before, .fa-repeat:before {
  content: "";
}

.fa-refresh:before {
  content: "";
}

.fa-list-alt:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-volume-down:before {
  content: "";
}

.fa-volume-up:before {
  content: "";
}

.fa-qrcode:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-bookmark:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-camera:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-list:before {
  content: "";
}

.fa-dedent:before, .fa-outdent:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-video-camera:before {
  content: "";
}

.fa-photo:before, .fa-image:before, .fa-picture-o:before {
  content: "";
}

.fa-pencil:before {
  content: "";
}

.fa-map-marker:before {
  content: "";
}

.fa-adjust:before {
  content: "";
}

.fa-tint:before {
  content: "";
}

.fa-edit:before, .fa-pencil-square-o:before {
  content: "";
}

.fa-share-square-o:before {
  content: "";
}

.fa-check-square-o:before {
  content: "";
}

.fa-arrows:before {
  content: "";
}

.fa-step-backward:before {
  content: "";
}

.fa-fast-backward:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-fast-forward:before {
  content: "";
}

.fa-step-forward:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-plus-circle:before {
  content: "";
}

.fa-minus-circle:before {
  content: "";
}

.fa-times-circle:before {
  content: "";
}

.fa-check-circle:before {
  content: "";
}

.fa-question-circle:before {
  content: "";
}

.fa-info-circle:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-times-circle-o:before {
  content: "";
}

.fa-check-circle-o:before {
  content: "";
}

.fa-ban:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-mail-forward:before, .fa-share:before {
  content: "";
}

.fa-expand:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-plus:before {
  content: "";
}

.fa-minus:before {
  content: "";
}

.fa-asterisk:before {
  content: "";
}

.fa-exclamation-circle:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-leaf:before {
  content: "";
}

.fa-fire:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-warning:before, .fa-exclamation-triangle:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-random:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-shopping-cart:before {
  content: "";
}

.fa-folder:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-arrows-v:before {
  content: "";
}

.fa-arrows-h:before {
  content: "";
}

.fa-bar-chart-o:before, .fa-bar-chart:before {
  content: "";
}

.fa-twitter-square:before {
  content: "";
}

.fa-facebook-square:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-gears:before, .fa-cogs:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-thumbs-o-up:before {
  content: "";
}

.fa-thumbs-o-down:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-heart-o:before {
  content: "";
}

.fa-sign-out:before {
  content: "";
}

.fa-linkedin-square:before {
  content: "";
}

.fa-thumb-tack:before {
  content: "";
}

.fa-external-link:before {
  content: "";
}

.fa-sign-in:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-github-square:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-lemon-o:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-square-o:before {
  content: "";
}

.fa-bookmark-o:before {
  content: "";
}

.fa-phone-square:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-facebook-f:before, .fa-facebook:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-credit-card:before {
  content: "";
}

.fa-feed:before, .fa-rss:before {
  content: "";
}

.fa-hdd-o:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-hand-o-right:before {
  content: "";
}

.fa-hand-o-left:before {
  content: "";
}

.fa-hand-o-up:before {
  content: "";
}

.fa-hand-o-down:before {
  content: "";
}

.fa-arrow-circle-left:before {
  content: "";
}

.fa-arrow-circle-right:before {
  content: "";
}

.fa-arrow-circle-up:before {
  content: "";
}

.fa-arrow-circle-down:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-tasks:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-arrows-alt:before {
  content: "";
}

.fa-group:before, .fa-users:before {
  content: "";
}

.fa-chain:before, .fa-link:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-cut:before, .fa-scissors:before {
  content: "";
}

.fa-copy:before, .fa-files-o:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-save:before, .fa-floppy-o:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-navicon:before, .fa-reorder:before, .fa-bars:before {
  content: "";
}

.fa-list-ul:before {
  content: "";
}

.fa-list-ol:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-table:before {
  content: "";
}

.fa-magic:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-pinterest-square:before {
  content: "";
}

.fa-google-plus-square:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-money:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-columns:before {
  content: "";
}

.fa-unsorted:before, .fa-sort:before {
  content: "";
}

.fa-sort-down:before, .fa-sort-desc:before {
  content: "";
}

.fa-sort-up:before, .fa-sort-asc:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-rotate-left:before, .fa-undo:before {
  content: "";
}

.fa-legal:before, .fa-gavel:before {
  content: "";
}

.fa-dashboard:before, .fa-tachometer:before {
  content: "";
}

.fa-comment-o:before {
  content: "";
}

.fa-comments-o:before {
  content: "";
}

.fa-flash:before, .fa-bolt:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-paste:before, .fa-clipboard:before {
  content: "";
}

.fa-lightbulb-o:before {
  content: "";
}

.fa-exchange:before {
  content: "";
}

.fa-cloud-download:before {
  content: "";
}

.fa-cloud-upload:before {
  content: "";
}

.fa-user-md:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-bell-o:before {
  content: "";
}

.fa-coffee:before {
  content: "";
}

.fa-cutlery:before {
  content: "";
}

.fa-file-text-o:before {
  content: "";
}

.fa-building-o:before {
  content: "";
}

.fa-hospital-o:before {
  content: "";
}

.fa-ambulance:before {
  content: "";
}

.fa-medkit:before {
  content: "";
}

.fa-fighter-jet:before {
  content: "";
}

.fa-beer:before {
  content: "";
}

.fa-h-square:before {
  content: "";
}

.fa-plus-square:before {
  content: "";
}

.fa-angle-double-left:before {
  content: "";
}

.fa-angle-double-right:before {
  content: "";
}

.fa-angle-double-up:before {
  content: "";
}

.fa-angle-double-down:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-angle-down:before {
  content: "";
}

.fa-desktop:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-tablet:before {
  content: "";
}

.fa-mobile-phone:before, .fa-mobile:before {
  content: "";
}

.fa-circle-o:before {
  content: "";
}

.fa-quote-left:before {
  content: "";
}

.fa-quote-right:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-mail-reply:before, .fa-reply:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-folder-o:before {
  content: "";
}

.fa-folder-open-o:before {
  content: "";
}

.fa-smile-o:before {
  content: "";
}

.fa-frown-o:before {
  content: "";
}

.fa-meh-o:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-keyboard-o:before {
  content: "";
}

.fa-flag-o:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-mail-reply-all:before, .fa-reply-all:before {
  content: "";
}

.fa-star-half-empty:before, .fa-star-half-full:before, .fa-star-half-o:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-code-fork:before {
  content: "";
}

.fa-unlink:before, .fa-chain-broken:before {
  content: "";
}

.fa-question:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-exclamation:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-shield:before {
  content: "";
}

.fa-calendar-o:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-chevron-circle-left:before {
  content: "";
}

.fa-chevron-circle-right:before {
  content: "";
}

.fa-chevron-circle-up:before {
  content: "";
}

.fa-chevron-circle-down:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-unlock-alt:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-ellipsis-h:before {
  content: "";
}

.fa-ellipsis-v:before {
  content: "";
}

.fa-rss-square:before {
  content: "";
}

.fa-play-circle:before {
  content: "";
}

.fa-ticket:before {
  content: "";
}

.fa-minus-square:before {
  content: "";
}

.fa-minus-square-o:before {
  content: "";
}

.fa-level-up:before {
  content: "";
}

.fa-level-down:before {
  content: "";
}

.fa-check-square:before {
  content: "";
}

.fa-pencil-square:before {
  content: "";
}

.fa-external-link-square:before {
  content: "";
}

.fa-share-square:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-toggle-down:before, .fa-caret-square-o-down:before {
  content: "";
}

.fa-toggle-up:before, .fa-caret-square-o-up:before {
  content: "";
}

.fa-toggle-right:before, .fa-caret-square-o-right:before {
  content: "";
}

.fa-euro:before, .fa-eur:before {
  content: "";
}

.fa-gbp:before {
  content: "";
}

.fa-dollar:before, .fa-usd:before {
  content: "";
}

.fa-rupee:before, .fa-inr:before {
  content: "";
}

.fa-cny:before, .fa-rmb:before, .fa-yen:before, .fa-jpy:before {
  content: "";
}

.fa-ruble:before, .fa-rouble:before, .fa-rub:before {
  content: "";
}

.fa-won:before, .fa-krw:before {
  content: "";
}

.fa-bitcoin:before, .fa-btc:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-file-text:before {
  content: "";
}

.fa-sort-alpha-asc:before {
  content: "";
}

.fa-sort-alpha-desc:before {
  content: "";
}

.fa-sort-amount-asc:before {
  content: "";
}

.fa-sort-amount-desc:before {
  content: "";
}

.fa-sort-numeric-asc:before {
  content: "";
}

.fa-sort-numeric-desc:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-youtube-square:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-xing-square:before {
  content: "";
}

.fa-youtube-play:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-bitbucket-square:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-tumblr-square:before {
  content: "";
}

.fa-long-arrow-down:before {
  content: "";
}

.fa-long-arrow-up:before {
  content: "";
}

.fa-long-arrow-left:before {
  content: "";
}

.fa-long-arrow-right:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-female:before {
  content: "";
}

.fa-male:before {
  content: "";
}

.fa-gittip:before, .fa-gratipay:before {
  content: "";
}

.fa-sun-o:before {
  content: "";
}

.fa-moon-o:before {
  content: "";
}

.fa-archive:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-vk:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-arrow-circle-o-right:before {
  content: "";
}

.fa-arrow-circle-o-left:before {
  content: "";
}

.fa-toggle-left:before, .fa-caret-square-o-left:before {
  content: "";
}

.fa-dot-circle-o:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-vimeo-square:before {
  content: "";
}

.fa-turkish-lira:before, .fa-try:before {
  content: "";
}

.fa-plus-square-o:before {
  content: "";
}

.fa-space-shuttle:before {
  content: "";
}

.fa-slack:before {
  content: "";
}

.fa-envelope-square:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-institution:before, .fa-bank:before, .fa-university:before {
  content: "";
}

.fa-mortar-board:before, .fa-graduation-cap:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-reddit-square:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-fax:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-spoon:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-behance-square:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-steam-square:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-automobile:before, .fa-car:before {
  content: "";
}

.fa-cab:before, .fa-taxi:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-file-pdf-o:before {
  content: "";
}

.fa-file-word-o:before {
  content: "";
}

.fa-file-excel-o:before {
  content: "";
}

.fa-file-powerpoint-o:before {
  content: "";
}

.fa-file-photo-o:before, .fa-file-picture-o:before, .fa-file-image-o:before {
  content: "";
}

.fa-file-zip-o:before, .fa-file-archive-o:before {
  content: "";
}

.fa-file-sound-o:before, .fa-file-audio-o:before {
  content: "";
}

.fa-file-movie-o:before, .fa-file-video-o:before {
  content: "";
}

.fa-file-code-o:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-life-bouy:before, .fa-life-buoy:before, .fa-life-saver:before, .fa-support:before, .fa-life-ring:before {
  content: "";
}

.fa-circle-o-notch:before {
  content: "";
}

.fa-ra:before, .fa-resistance:before, .fa-rebel:before {
  content: "";
}

.fa-ge:before, .fa-empire:before {
  content: "";
}

.fa-git-square:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-y-combinator-square:before, .fa-yc-square:before, .fa-hacker-news:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-wechat:before, .fa-weixin:before {
  content: "";
}

.fa-send:before, .fa-paper-plane:before {
  content: "";
}

.fa-send-o:before, .fa-paper-plane-o:before {
  content: "";
}

.fa-history:before {
  content: "";
}

.fa-circle-thin:before {
  content: "";
}

.fa-header:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-sliders:before {
  content: "";
}

.fa-share-alt:before {
  content: "";
}

.fa-share-alt-square:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-soccer-ball-o:before, .fa-futbol-o:before {
  content: "";
}

.fa-tty:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-newspaper-o:before {
  content: "";
}

.fa-wifi:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-bell-slash-o:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-at:before {
  content: "";
}

.fa-eyedropper:before {
  content: "";
}

.fa-paint-brush:before {
  content: "";
}

.fa-birthday-cake:before {
  content: "";
}

.fa-area-chart:before {
  content: "";
}

.fa-pie-chart:before {
  content: "";
}

.fa-line-chart:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-lastfm-square:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-cc:before {
  content: "";
}

.fa-shekel:before, .fa-sheqel:before, .fa-ils:before {
  content: "";
}

.fa-meanpath:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-dashcube:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-diamond:before {
  content: "";
}

.fa-ship:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-heartbeat:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-intersex:before, .fa-transgender:before {
  content: "";
}

.fa-transgender-alt:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-mars-stroke-v:before {
  content: "";
}

.fa-mars-stroke-h:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-facebook-official:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-user-times:before {
  content: "";
}

.fa-hotel:before, .fa-bed:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-train:before {
  content: "";
}

.fa-subway:before {
  content: "";
}

.fa-medium:before {
  content: "";
}

.fa-yc:before, .fa-y-combinator:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-battery-4:before, .fa-battery:before, .fa-battery-full:before {
  content: "";
}

.fa-battery-3:before, .fa-battery-three-quarters:before {
  content: "";
}

.fa-battery-2:before, .fa-battery-half:before {
  content: "";
}

.fa-battery-1:before, .fa-battery-quarter:before {
  content: "";
}

.fa-battery-0:before, .fa-battery-empty:before {
  content: "";
}

.fa-mouse-pointer:before {
  content: "";
}

.fa-i-cursor:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-sticky-note:before {
  content: "";
}

.fa-sticky-note-o:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-balance-scale:before {
  content: "";
}

.fa-hourglass-o:before {
  content: "";
}

.fa-hourglass-1:before, .fa-hourglass-start:before {
  content: "";
}

.fa-hourglass-2:before, .fa-hourglass-half:before {
  content: "";
}

.fa-hourglass-3:before, .fa-hourglass-end:before {
  content: "";
}

.fa-hourglass:before {
  content: "";
}

.fa-hand-grab-o:before, .fa-hand-rock-o:before {
  content: "";
}

.fa-hand-stop-o:before, .fa-hand-paper-o:before {
  content: "";
}

.fa-hand-scissors-o:before {
  content: "";
}

.fa-hand-lizard-o:before {
  content: "";
}

.fa-hand-spock-o:before {
  content: "";
}

.fa-hand-pointer-o:before {
  content: "";
}

.fa-hand-peace-o:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-tripadvisor:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-odnoklassniki-square:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-tv:before, .fa-television:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-500px:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-calendar-plus-o:before {
  content: "";
}

.fa-calendar-minus-o:before {
  content: "";
}

.fa-calendar-times-o:before {
  content: "";
}

.fa-calendar-check-o:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-map-signs:before {
  content: "";
}

.fa-map-o:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-commenting:before {
  content: "";
}

.fa-commenting-o:before {
  content: "";
}

.fa-houzz:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-credit-card-alt:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-pause-circle:before {
  content: "";
}

.fa-pause-circle-o:before {
  content: "";
}

.fa-stop-circle:before {
  content: "";
}

.fa-stop-circle-o:before {
  content: "";
}

.fa-shopping-bag:before {
  content: "";
}

.fa-shopping-basket:before {
  content: "";
}

.fa-hashtag:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-percent:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-universal-access:before {
  content: "";
}

.fa-wheelchair-alt:before {
  content: "";
}

.fa-question-circle-o:before {
  content: "";
}

.fa-blind:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-volume-control-phone:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-assistive-listening-systems:before {
  content: "";
}

.fa-asl-interpreting:before, .fa-american-sign-language-interpreting:before {
  content: "";
}

.fa-deafness:before, .fa-hard-of-hearing:before, .fa-deaf:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-signing:before, .fa-sign-language:before {
  content: "";
}

.fa-low-vision:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-viadeo-square:before {
  content: "";
}

.fa-snapchat:before {
  content: "";
}

.fa-snapchat-ghost:before {
  content: "";
}

.fa-snapchat-square:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-google-plus-circle:before, .fa-google-plus-official:before {
  content: "";
}

.fa-fa:before, .fa-font-awesome:before {
  content: "";
}

.fa-handshake-o:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-envelope-open-o:before {
  content: "";
}

.fa-linode:before {
  content: "";
}

.fa-address-book:before {
  content: "";
}

.fa-address-book-o:before {
  content: "";
}

.fa-vcard:before, .fa-address-card:before {
  content: "";
}

.fa-vcard-o:before, .fa-address-card-o:before {
  content: "";
}

.fa-user-circle:before {
  content: "";
}

.fa-user-circle-o:before {
  content: "";
}

.fa-user-o:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-drivers-license:before, .fa-id-card:before {
  content: "";
}

.fa-drivers-license-o:before, .fa-id-card-o:before {
  content: "";
}

.fa-quora:before {
  content: "";
}

.fa-free-code-camp:before {
  content: "";
}

.fa-telegram:before {
  content: "";
}

.fa-thermometer-4:before, .fa-thermometer:before, .fa-thermometer-full:before {
  content: "";
}

.fa-thermometer-3:before, .fa-thermometer-three-quarters:before {
  content: "";
}

.fa-thermometer-2:before, .fa-thermometer-half:before {
  content: "";
}

.fa-thermometer-1:before, .fa-thermometer-quarter:before {
  content: "";
}

.fa-thermometer-0:before, .fa-thermometer-empty:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-bathtub:before, .fa-s15:before, .fa-bath:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-times-rectangle:before, .fa-window-close:before {
  content: "";
}

.fa-times-rectangle-o:before, .fa-window-close-o:before {
  content: "";
}

.fa-bandcamp:before {
  content: "";
}

.fa-grav:before {
  content: "";
}

.fa-etsy:before {
  content: "";
}

.fa-imdb:before {
  content: "";
}

.fa-ravelry:before {
  content: "";
}

.fa-eercast:before {
  content: "";
}

.fa-microchip:before {
  content: "";
}

.fa-snowflake-o:before {
  content: "";
}

.fa-superpowers:before {
  content: "";
}

.fa-wpexplorer:before {
  content: "";
}

.fa-meetup:before {
  content: "";
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  width: auto;
  height: auto;
  clip: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.login-center-viewport {
  z-index: 100;
  width: 50vw;
  filter: drop-shadow(0 0 100px #fff);
  align-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*# sourceMappingURL=index.cb516798.css.map */
