@import 'npm:@picocss/pico';
@import 'npm:font-awesome/css/font-awesome.css';

.login-center-viewport {
    z-index: 100;
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 50vw;
    filter: drop-shadow(white 0px 0px 100px);
    align-items: center;
    align-content: center;
}
